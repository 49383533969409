import React, { useState, useRef, useEffect } from "react";
import "./CSS/Support.css";
import "./CSS/Initiatives.css";
import Cookies from "js-cookie";

import { MdArrowBack } from "react-icons/md";
import { HOST_NAME } from "./const";
import axios from "axios";

const OneOnOne = () => {
  const [data, setData] = useState(null);
  const [isBucket, setIsBucket] = useState(false);
  const [buckets, setBuckets] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState();

  const changeBucket = (d, q) => {
    setIsBucket(!isBucket);
    setBuckets(d);
    setSelectedQuestion(q);
  };
  const tabsRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);

  const tabs = ["Career", "Culture"];

  useEffect(() => {
    console.log(Cookies.get("token"));
    const fetchJavaScriptFile = async () => {
      try {
        await axios
          .get(`${HOST_NAME}/route/get-oneonone`, {
            method: "GET",
            headers: {
              token: Cookies.get("token"),
            },
          })
          .then(async (res) => {
            setData(res.data.data);
          })
          .catch((e) => console.log(e));
      } catch (error) {
        console.error("Error fetching JS file:", error);
      }
    };

    fetchJavaScriptFile();
  }, []);

  if (!isBucket) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50px",
          left: "18%",
          width: "65vw",
          border: "1px solid rgba(0,0,0,0.3)",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          className="tabs"
          style={{
            width: "70vw",
          }}
          ref={tabsRef}
        >
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tab-button ${activeTab === index ? "active" : ""}`}
              onClick={() => setActiveTab(index)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#0042A6",
                borderRadius: "8px",
                width: "200px",
                height: "20px",
                margin: "10px",
                color: `${activeTab === index ? "#fff06b" : "#fff"}`,
                whiteSpace: "nowrap",
              }}
            >
              <p
                style={{ width: "100%", fontSize: "14px", textAlign: "center" }}
              >
                {tab}
              </p>
            </div>
          ))}
        </div>

        {data !== null &&
          data[activeTab].datas.map((d, index) => (
            <div>
              <p style={{ fontWeight: "700" }}>{d.question}</p>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    width: "65%",
                    marginTop: "-10px",
                  }}
                >
                  {d.inference}
                </p>
                <div
                  onClick={() => changeBucket(d.buckets, d.question)}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#0042A6",
                    borderRadius: "10px",
                    paddingLeft: "7px",
                    paddingRight: "7px",
                    cursor: "pointer",
                    width: "200px",
                    color: "#fff",
                  }}
                >
                  <p>{"Check answer >>>"}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  } else {
    return (
      <div
        style={{
          position: "absolute",
          top: "50px",
          left: "18%",
          width: "65vw",
          border: "1px solid rgba(0,0,0,0.3)",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            // justifyContent: "flex-between",
            alignItems: "center",
          }}
        >
          <MdArrowBack
            onClick={() => changeBucket()}
            style={{ cursor: "pointer", float: "lect" }}
            size={30}
            color="#000"
          />
          <p style={{ marginLeft: "50px", fontWeight: "700" }}>
            {selectedQuestion}
          </p>
        </div>
        <div
          className="grid-buckets"
          //   style={{
          //     display: "flex",
          //     justifyContent: "center",
          //     alignItems: "center",
          //     flexDirection: "row",
          //   }}
        >
          {buckets !== null &&
            buckets.map((b) => (
              <div
                style={{
                  display: "flex",
                  //   justifyContent: "center",
                  //   alignItems: "center",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: "1px solid rgba(0,0,0,0.5)",
                  margin: "10px",
                }}
              >
                <div
                  style={{
                    borderBottom: "1px solid black",
                    backgroundColor: "#0042A6",
                    paddingLeft: "15px",
                    borderRadius: "10px",
                  }}
                >
                  <p
                    style={{
                      color: "#fff",
                      fontWeight: "800",
                      textAlign: "center",
                    }}
                  >
                    {b.name}
                  </p>
                </div>
                <div
                  className="tags-container"
                  style={{ maxHeight: "300px", overflowY: "auto" }}
                >
                  <ul>
                    {b.tags.map((t) => (
                      <li style={{ paddingLeft: "5px", margin: "10px" }}>
                        {t.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
};

export default OneOnOne;
