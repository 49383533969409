import React, { useState } from "react";
import "./CSS/Support.css";
import { GrAttachment } from "react-icons/gr";
import axios from "axios";
import { HOST_NAME } from "./const";
import Cookies from "js-cookie";

const Support = () => {
  const faq = [
    {
      qn: "What is the purpose of this Thriving Workplace Survey?",
      ans: "This survey assesses workplace health across various levers, identifying strengths and areas for improvement to create a thriving work environment.",
    },
    {
      qn: "What are the key takeaways from this report?",
      ans: "The report highlights areas where the organization excels, as well as opportunities for enhancing employee well-being and productivity.",
    },
    {
      qn: "How will the results be used to improve the workplace?",
      ans: "Leadership will use the insights to implement targeted initiatives, improve policies, and foster a more supportive work environment."
    },
    {
      qn: "What actions will be taken based on this report?",
      ans: "Based on the findings, the organization can create action plans, run interventions, and track progress in key focus areas."
    },
    {
      qn: "How can employees contribute to making the workplace thrive?",
      ans: "Employees can engage in discussions, provide suggestions, participate in initiatives, and take ownership of workplace well-being."
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");

  const toggleDropdown = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const raiseTicket = async () => {
    await axios
      .post(
        `${HOST_NAME}/route/raise-ticket`,
        {
          subject: subject,
          description: description,
        },
        {
          headers: {
            token: Cookies.get("token"),
          },
        }
      )
      .then((res) => {
        alert("Ticket raised successfully");
        setSubject("");
        setDescription("");
      })
      .catch((e) => {
        console.log("Failed to raise a ticket");
      });
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "50px",
        left: "18%",
        width: "60vw",
      }}
    >
      <div
        style={{ width: "60vw", height: "1px", backgroundColor: "#CFCFCF" }}
      />
      <p style={{ fontWeight: "510", fontSize: "20px" }}>FAQs</p>
      <div
        style={{ width: "60vw", height: "1px", backgroundColor: "#CFCFCF" }}
      />

      {faq.map((item, index) => (
        <div className="dropdown" key={index}>
          <div
            className="dropdown-toggle"
            onClick={() => toggleDropdown(index)}
          >
            <span>{item.qn}</span>
            <i className={`arrow ${openIndex === index ? "up" : "down"}`} />
          </div>
          <div
            className={`dropdown-content ${openIndex === index ? "open" : ""}`}
          >
            {item.ans}
          </div>
        </div>
      ))}

      <div style={{ marginTop: "5%" }}>
        <div
          style={{ width: "60vw", height: "1px", backgroundColor: "#CFCFCF" }}
        />
        <p style={{ fontWeight: "510", fontSize: "20px" }}>Raise a Ticket</p>
        <div
          style={{ width: "60vw", height: "1px", backgroundColor: "#CFCFCF" }}
        />
        <p style={{ color: "#2961B6", fontWeight: "450", fontSize: "16px" }}>
          If you need help or have any concerns, just 'Send us a Message' and
          we'll help you right away.
        </p>
        <p style={{ fontWeight: "510", fontSize: "16px" }}>
          Subject <span style={{ color: "red" }}>*</span>
        </p>
        <input
          style={{
            width: "30vw",
            border: "1px solid #888888",
            borderRadius: "8px",
            padding: "6px 14px 6px 14px",
          }}
          type="text"
          value={subject}
          onChange={(event) => {
            setSubject(event.target.value);
          }}
          placeholder="Ex. My lists aren’t showing on my homepage when i open my app"
        />
        <p style={{ fontWeight: "510", fontSize: "16px" }}>
          Do you have any additional details that we should know about?
          <span style={{ color: "red" }}> *</span>
        </p>

        <textarea
          style={{
            width: "30vw",
            border: "1px solid #888888",
            borderRadius: "8px",
            padding: "6px 14px 6px 14px",
            resize: "none",
          }}
          name="Hello"
          rows={5}
          value={description}
          onChange={(event) => {
            setDescription(event.target.value);
          }}
          placeholder="Include anything that you weren’t able to capture."
        />
        {/* <div
          style={{
            width: "30vw",
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <p>Attach file</p>
          <GrAttachment style={{ marginLeft: "10px" }} color="#00" size={20} />
        </div> */}

        <div onClick={() => raiseTicket()} className="raise-btn">
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <img
              alt=""
              style={{ width: "30px", height: "30px" }}
              src={require("../assets/message-text.png")}
            />
            <p
              style={{ color: "#2961B6", fontWeight: "510", fontSize: "16px" }}
            >
              Submit
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
