import React, { useRef, useEffect, useState } from "react";
import "./CSS/Dashboard.css";
import { BELOW_80, HOST_NAME } from "./const";
import { Doughnut, Bar } from "react-chartjs-2";
import { MdArrowBack } from "react-icons/md";
import { Chart, registerables } from "chart.js";
import axios from "axios";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Cookies from "js-cookie";
import { GrAttachment } from "react-icons/gr";
Chart.register(...registerables);

const Dashboard = () => {
  const chartRefs = useRef([]);

  const [levers, setLevers] = useState([]);
  const [category, setCategory] = useState([]);
  const [isDashboard, setIsDashboard] = useState(true);
  const [leverDetailsIndex, setLeverDetailsIndex] = useState(null);

  const [inferences, setInferences] = useState({
    "Career Health": [
      {
        inf: "Employees feel significantly under-rewarded for their special contributions and performance. This very low score indicates dissatisfaction with the company’s reward system and a lack of appreciation for individual efforts.",
      },
      {
        inf: "Many employees do not find the current rewards and recognition initiatives motivating. This score suggests that existing recognition efforts may not be effective in inspiring employees to perform at their best.",
      },
      {
        inf: "Employees feel that managers and executives are not fully engaged in mentoring or coaching their career development. This moderately low score points to a gap in leadership support.",
      },
      {
        inf: "While some employees feel supported by their managers, many believe that managers are not sufficiently involved in guiding their career progression. This score suggests a need for more active managerial involvement.",
      },
      {
        inf: "Employees are dissatisfied with the timeliness and quality of feedback from management. This low score indicates that performance feedback is either inconsistent, delayed, or not constructive enough.",
      },
      {
        inf: "Employees are not fully convinced that their managers act as a solid support system for their growth and development. This score indicates that managers could be more involved in nurturing their teams.",
      },
      {
        inf: "While employees recognize some opportunities for professional growth, this score suggests that there is still room for improvement in how the company supports career development.",
      },
      {
        inf: "Employees are uncertain about their long-term career prospects within the company. This score indicates that many do not see clear career advancement opportunities or a future beyond their current roles.",
      },
      {
        inf: "Employees feel that they are not consistently challenged or provided with opportunities to stretch their abilities. This score suggests that while some challenging situations arise, more could be done to promote continuous growth.",
      },
    ],
    "Mental Health": [
      {
        inf: "While some employees feel happy and secure in their roles, this score suggests that many employees may have concerns about job security or overall satisfaction with their roles.",
      },
      {
        inf: "Most employees feel that their workload is manageable, indicating that the company has been relatively successful in balancing task distribution and workload management.",
      },
      {
        inf: "Many employees feel that they lack close connections or friendships at work. This score highlights an issue with social support within the workplace, which can affect morale and mental well-being.",
      },
      {
        inf: "Employees feel a sense of guilt when taking leave, suggesting that the company culture may not fully support employees in taking time off for rest and recovery.",
      },
      {
        inf: "Many employees feel that it is difficult to take personal time off when working from home, indicating a blurred line between work and personal life.",
      },
      {
        inf: "Employees do not feel well-equipped with coping mechanisms to manage stress, suggesting that there may be insufficient mental health support or stress management resources available.",
      },
      {
        inf: "While some employees feel that the work environment is supportive, this score suggests that there is still room for improvement in maintaining a consistently positive and supportive atmosphere.",
      },
      {
        inf: "Employees do not feel that their voices are consistently heard, indicating that there may be barriers to open communication or limited opportunities for employee input.",
      },
    ],
    "Financial Health": [
      {
        inf: "Employees feel significantly dissatisfied with their current salary in relation to their financial goals. This extremely low score suggests that employees believe their compensation is inadequate for their personal financial aspirations.",
      },
      {
        inf: "Many employees are not able to consistently contribute to emergency funds, indicating financial stress or constraints. This score suggests that employees may feel they are unable to save adequately for emergencies or unexpected situations.",
      },
      {
        inf: "While this score is closer to neutral, it reflects that a significant number of employees are the sole breadwinners in their households, which may contribute to financial pressure and insecurity.",
      },
      {
        inf: "Employees are divided on whether the company provides adequate support during times of financial need. While some may feel supported, others may believe that the company could do more to assist employees during challenging times.",
      },
      {
        inf: "Employees believe that the company does not offer sufficient long-term financial planning options, such as retirement savings plans or investment opportunities. This low score highlights a need for more robust financial schemes.",
      },
    ],
    "Intellectual Health": [
      {
        inf: "Employees do not feel strongly supported in consistently learning and developing new skills. This score indicates that while some initiatives for learning may exist, they are not fully effective or accessible to all.",
      },
      {
        inf: "While some employees take initiative in seeking learning opportunities, the score suggests there is still room for the company to inspire more employees to voluntarily engage in learning activities.",
      },
      {
        inf: "Employees do not consistently feel that mistakes are treated as opportunities for growth. This score indicates that the company culture around failure might be seen as punitive or risk-averse, hindering innovation.",
      },
      {
        inf: "Employees feel that they lack adequate access to the tools and resources needed to innovate. This low score suggests that there are barriers to creativity and experimentation, potentially due to limited resources or support.",
      },
      {
        inf: "Employees feel that their skills and potential are not fully utilized in their current roles. This score suggests that there may be a mismatch between employees' capabilities and the tasks or responsibilities they are assigned.",
      },
      {
        inf: "Most employees feel that the company drives a culture of achieving results and striving for excellence. This is a positive indicator that employees are focused and motivated to perform at high levels.",
      },
      {
        inf: "Employees feel that their efforts and initiatives are not consistently valued by the company. This low score reflects a need for greater recognition and appreciation for employee contributions.",
      },
      {
        inf: "Many employees feel that they are regularly challenged in their roles, which helps encourage their growth. This score reflects a positive environment where employees are given opportunities to stretch their abilities and develop.",
      },
    ],
    "Social Health": [
      {
        inf: "Employees generally feel included and valued as part of their teams, indicating a positive sense of belonging and inclusion within collaborative environments.",
      },
      {
        inf: "While collaboration occurs, there is room for improvement in how often teams come together to collaborate effectively. This score suggests that more could be done to encourage teamwork and cross-functional collaboration.",
      },
      {
        inf: "Employees feel that candid conversations are not a consistent part of the workplace culture, suggesting that open communication may be limited or not fully encouraged.",
      },
      {
        inf: "Employees feel that the workplace lacks a celebratory culture, indicating that achievements and milestones are not being acknowledged or celebrated adequately.",
      },
      {
        inf: "There is a lack of strong mutual trust among employees, suggesting that the workplace culture may have issues related to interpersonal trust and teamwork.",
      },
    ],
    "Cultural Health": [
      {
        inf: "Employees have a strong understanding of the company’s values, indicating effective communication around organizational principles.",
      },
      {
        inf: "While employees understand the company’s values, there is a gap in how well these values are exhibited by their peers. This score indicates that employees may perceive a disconnect between stated values and everyday behavior.",
      },
      {
        inf: "Employees feel that the company’s values are somewhat reflected in the workplace, but there is room for improvement in how consistently these values are applied in decision-making and practices.",
      },
      {
        inf: "Employees feel that the company is not consistently creating meaningful experiences or moments that resonate with them. This score indicates a lack of impactful cultural practices.",
      },
      {
        inf: "Employees do not view the company’s people practices as particularly unique or impressive. This score suggests that employees believe there is room for improvement in how the company manages its people.",
      },
      {
        inf: "Employees generally believe the company operates ethically, which is a positive indicator of trust in the organization’s leadership and policies.",
      },
      {
        inf: "Many employees are not fully satisfied with the company’s ethical conduct policies, indicating that there may be concerns about how these policies are communicated or enforced.",
      },
      {
        inf: "While some employees feel that fairness is upheld, the score suggests that there are still concerns about whether all employees are treated equitably, regardless of background.",
      },
      {
        inf: "Employees feel that performance evaluations and rewards are not always fair, which indicates dissatisfaction with the company's evaluation and reward systems.",
      },
      {
        inf: "Employees do not feel that the company provides a comfortable or inviting space for social interactions, which can affect workplace camaraderie and community-building.",
      },
    ],
    "Business Health": [
      {
        inf: "Employees are somewhat skeptical about the leadership’s ability to lead the company successfully. This score suggests that while some trust in leadership exists, there are concerns about the leadership's effectiveness in driving success.",
      },
      {
        inf: "Employees generally find management to be somewhat approachable, but there is room for improvement in making management more accessible and open to dialogue.",
      },
      {
        inf: "Employees believe that the company’s brand is well-regarded externally, which is a strong indicator of brand health and reputation.",
      },
      {
        inf: "While employees generally feel positively about the company, some are hesitant to recommend it to others. This indicates that there may be underlying concerns about the workplace environment or future prospects.",
      },
      {
        inf: "Employees recognize that the company’s brand makes a meaningful impact in the industry, but there is still room for greater influence.",
      },
      {
        inf: "While most employees are aware of the company’s benefits, there is still some uncertainty or lack of clarity around available perks and compensation packages.",
      },
      {
        inf: "Many employees do not find the existing perks and benefits compelling enough, which indicates dissatisfaction with the current compensation structure.",
      },
      {
        inf: "Employees find that accessing benefits is somewhat difficult or complicated, which may reduce the overall perceived value of these benefits.",
      },
      {
        inf: "Employees feel that the company’s metrics for measuring performance are inadequate or misaligned with the company’s goals. This low score indicates dissatisfaction with how success is tracked and evaluated.",
      },
      {
        inf: "Employees feel that the company’s systems and processes for executing strategies are somewhat lacking, which may hinder operational efficiency or performance.",
      },
      {
        inf: "Employees are not fully confident in management’s ability to hire and manage talent effectively. This score suggests that there may be issues with talent acquisition or employee management.",
      },
    ],
    "Digital and Tech Health": [
      {
        inf: "Employees are highly enthusiastic about new technological advancements at work. This strong score indicates that the workforce is open to innovation and eager to leverage new technologies in their roles.",
      },
      {
        inf: "Employees feel confident in their ability to quickly adapt to new technologies. This high score reflects a positive attitude towards learning and technological change in the workplace.",
      },
      {
        inf: "Employees believe that the technology available to them significantly enhances their efficiency and effectiveness at work. This score suggests that the company is providing the right technological tools for employees to perform their jobs well.",
      },
      {
        inf: "While some employees feel that the company leverages data and analytics effectively, this score suggests there are concerns about how well data is being used to manage people and drive business decisions.",
      },
    ],
    "Physiological Health": [
      {
        inf: "Many employees find it challenging to incorporate regular movement into their workday. This indicates that employees may feel tied to their desks or unaware of the importance of taking breaks to move.",
      },
      {
        inf: "While most employees feel that their sleep quality is not significantly affecting their work, some still experience a lack of energy and focus, likely due to sleep-related issues.",
      },
      {
        inf: "A significant number of employees do not feel physically refreshed and rejuvenated after their workday, indicating potential burnout or exhaustion due to work demands.",
      },
      {
        inf: "Employees are not consistently taking conscious actions to prioritize their health and well-being, suggesting that they might not feel adequately motivated or supported to do so.",
      },
      {
        inf: "There is a gap in how much employees feel encouraged by the company to prioritize their health, signaling that existing wellness programs may not be effectively reaching or motivating the workforce.",
      },
    ],
    "Spiritual Health": [
      {
        inf: "Employees feel that their work is somewhat draining and does not consistently energize them. This score suggests that many employees may experience fatigue or lack of motivation in their roles.",
      },
      {
        inf: "Most employees feel a strong sense of purpose in their work. This is a significant strength for the organization, as employees who understand their purpose are more likely to be engaged and motivated.",
      },
      {
        inf: "Employees are unsure about the company’s level of social responsibility, with many perceiving that the organization could do more to contribute to social and environmental causes.",
      },
      {
        inf: "Employees feel they do not have enough opportunities to make a meaningful impact through their work. This low score highlights a disconnect between employees and the sense of making a tangible difference.",
      },
      {
        inf: "While some employees feel their work is fulfilling, there is still a significant portion that does not feel their work makes a meaningful impact on others.",
      },
      {
        inf: "Many employees are incorporating mindfulness practices into their workday, but some may not have fully embraced these strategies or feel they lack the time or support to do so.",
      },
    ],
  });
  const [insights, setInsights] = useState({
    "Physiological Health": [
      {
        inf: "Encouraging scheduled breaks, offering standing desks, and promoting walking meetings will help employees integrate movement into their daily routine. This will contribute to better physical well-being and prevent long-term discomfort from sedentary behavior.",
      },
      {
        inf: "Offering wellness workshops that focus on sleep hygiene and energy management can further support employees in maintaining high energy levels throughout the day. By promoting better sleep habits, the organization can enhance both productivity and employee well-being.",
      },
      {
        inf: "Implementing initiatives to support work-life balance, such as flexible working hours or promoting frequent breaks during the day, will help employees maintain better energy levels and feel more rejuvenated after work. This will positively impact both their personal and professional lives.",
      },
      {
        inf: "Providing health-oriented programs and promoting a workplace culture that values physical well-being will encourage employees to take more consistent actions towards improving their health. Initiatives like fitness challenges, wellness apps, and healthy snacks can help motivate employees to take better care of themselves.",
      },
      {
        inf: "To improve this perception, the company can amplify its focus on health and wellness by offering incentives for healthy behaviors, organizing fitness events, and increasing communication around the importance of physical well-being. This will help foster a more health-conscious culture within the organization.",
      },
    ],
    "Spiritual Health": [
      {
        inf: "To help employees feel more energized by their work, the company can focus on improving work-life balance, offering more meaningful and engaging projects, and providing opportunities for employees to align their roles with their passions and strengths. Supporting employees in finding work that is both fulfilling and energizing will improve morale and productivity.",
      },
      {
        inf: "The company should continue to reinforce this strong sense of purpose by regularly communicating the organization’s mission and how individual roles contribute to broader goals. Maintaining this clarity will help sustain high levels of engagement and satisfaction.",
      },
      {
        inf: "To improve this score, the company can enhance its corporate social responsibility (CSR) initiatives, communicate these efforts more clearly to employees, and encourage staff involvement in social impact projects. Demonstrating a stronger commitment to social responsibility will increase employees' pride in the company and strengthen their connection to its values.",
      },
      {
        inf: "The company can address this by creating more avenues for employees to contribute to socially responsible initiatives, volunteer opportunities, or projects with clear positive impacts. Encouraging employees to take ownership of impact-driven work will enhance their sense of purpose and fulfillment.",
      },
      {
        inf: "To foster a greater sense of fulfillment, the company can emphasize the broader impacts of employees’ work on the organization and society. Highlighting stories of how employees’ contributions positively affect others can reinforce the significance of their roles and help them feel more connected to their work.",
      },
      {
        inf: "The company can introduce mindfulness workshops and provide resources that encourage mindfulness practices at work, such as meditation sessions, quiet spaces, or stress-reduction programs. Promoting mindfulness will help employees manage stress and stay more focused and grounded throughout the day.",
      },
    ],
    "Financial Health": [
      {
        inf: "To address this concern, the company should conduct a compensation review to ensure salaries are competitive and aligned with industry standards. Offering more transparency around salary structures and providing opportunities for raises or bonuses tied to performance can help improve employee satisfaction in this area.",
      },
      {
        inf: "The company can support employees in financial planning by offering financial wellness programs, including workshops on budgeting, saving, and investing. Providing tools or resources to help employees build emergency funds will reduce financial stress and promote long-term financial well-being.",
      },
      {
        inf: "The company should consider providing additional support to employees who are the primary income earners, such as offering flexible work arrangements or financial planning resources. Helping these employees manage the stress of being the sole provider will enhance their financial stability and well-being.",
      },
      {
        inf: "The company can strengthen its support system by enhancing financial assistance programs, such as emergency loans, hardship funds, or providing more comprehensive benefits during times of crisis. Communicating these support systems clearly and ensuring accessibility will help employees feel more secure in times of financial need.",
      },
      {
        inf: "The company can improve this score by introducing or expanding long-term financial planning options, such as enhanced retirement plans, pension contributions, or investment education programs. Providing employees with the tools to plan for their long-term financial well-being will increase their financial security and overall satisfaction.",
      },
    ],
    "Intellectual Health": [
      {
        inf: "To improve this, the company can enhance its learning culture by offering more structured opportunities for skill development, such as workshops, certifications, or continuous learning platforms. Encouraging managers to prioritize learning within their teams and rewarding personal development can foster a culture of continuous improvement.",
      },
      {
        inf: "To encourage more employees to seek learning opportunities, the company can provide more visibility and accessibility to these resources. Offering personalized learning paths and recognizing employees who pursue development can further drive a culture of proactive learning.",
      },
      {
        inf: "Creating a more open culture where mistakes are embraced as learning experiences can foster innovation. Leadership can set the tone by sharing their own learning from failures and encouraging employees to experiment without fear of repercussions. This will help employees feel more comfortable with taking risks and pursuing innovative ideas.",
      },
      {
        inf: "The company can invest in providing better access to tools, technologies, and resources that facilitate innovation. Creating dedicated innovation spaces or providing employees with time and resources to work on creative projects can help improve this score and drive a more innovative environment.",
      },
      {
        inf: "The company can conduct skill assessments and offer role adjustments or stretch assignments to better align employee strengths with their responsibilities. Providing opportunities for employees to take on new challenges or lead projects can help ensure that their full potential is realized.",
      },
      {
        inf: "To sustain this positive momentum, the company should continue to set clear expectations for excellence and recognize employees who consistently achieve results. Reinforcing this drive through regular performance reviews and goal-setting can help maintain this positive culture.",
      },
      {
        inf: "Improving recognition programs, where employees’ efforts and initiatives are visibly appreciated and rewarded, can boost this score. Regular feedback, public acknowledgment of accomplishments, and opportunities for career advancement can help employees feel more valued.",
      },
      {
        inf: "To maintain this momentum, the company should continue to offer challenging assignments and projects that encourage employees to step out of their comfort zones. Providing access to additional resources and support for these challenges will help sustain a growth-focused culture.",
      },
    ],
    "Career Health": [
      {
        inf: "The company should focus on implementing a more robust recognition and reward system. Regularly acknowledging exceptional contributions through bonuses, public recognition, or other incentives can help improve employee morale and motivation.",
      },
      {
        inf: "Revamping recognition programs to align with employee preferences—whether through more frequent acknowledgment, non-monetary rewards, or career development incentives—can improve motivation. Collecting employee feedback on preferred recognition methods can make these programs more impactful.",
      },
      {
        inf: "Introducing formal mentorship programs and encouraging managers to take a more active role in employees’ development can enhance this perception. Regular check-ins and career development discussions can help foster a stronger support system for employees’ growth.",
      },
      {
        inf: "To address this, managers can be encouraged to provide more personalized career guidance through one-on-one sessions. Creating individual development plans that align employee goals with organizational needs will help employees feel more supported in their career journeys.",
      },
      {
        inf: "To improve, management can implement a more structured feedback process, ensuring that feedback is provided regularly and is both actionable and supportive. Introducing 360-degree feedback systems or real-time feedback tools can enhance communication and growth opportunities.",
      },
      {
        inf: "Managers should be trained to provide consistent support and mentorship. Encouraging managers to take an active interest in their team members’ career paths and offer regular guidance will help employees feel more confident and supported in their development.",
      },
      {
        inf: "To further enhance professional growth, the company can offer structured development programs, more frequent promotions, and cross-functional opportunities. Communicating clear pathways for advancement can motivate employees to invest in their professional growth within the organization.",
      },
      {
        inf: "To address this, the company should offer clearer career progression pathways and communicate long-term opportunities for employees. Providing internal mobility programs and success stories of career growth within the company can help employees envision a long-term future.",
      },
      {
        inf: "To encourage growth, the company can offer more challenging projects, stretch assignments, or cross-training opportunities. Providing employees with tasks that push them outside their comfort zones will enhance their skill sets and career development.",
      },
    ],
    "Business Health": [
      {
        inf: "The company can address this by improving transparency around decision-making and showcasing leadership's vision and strategic direction. Regular updates on company performance and leadership-led initiatives can help build trust and confidence in the leadership team’s ability.",
      },
      {
        inf: "Encouraging more informal interactions, open-door policies, and regular one-on-one sessions with leadership can enhance this perception. When employees feel that management is genuinely approachable, they are more likely to engage and share feedback, fostering a more open culture.",
      },
      {
        inf: "The company should continue reinforcing its positive brand image through consistent messaging and quality service. Employees should also be involved in brand initiatives to ensure that the internal perception aligns with external views, fostering a sense of pride in the company.",
      },
      {
        inf: "The company should focus on improving areas that influence employee referrals, such as work-life balance, growth opportunities, and compensation. Satisfied employees who are proud of their work experience are more likely to act as brand ambassadors and recommend the company.",
      },
      {
        inf: "To further elevate the brand's impact, the company can invest in thought leadership, partnerships, and innovative projects that position it as a leader in its field. Engaging employees in brand-building efforts can help them feel more connected to the company's mission.",
      },
      {
        inf: "The company can improve communication around employee benefits by offering more detailed explanations and reminders of available perks. Regularly updating employees on any changes and highlighting underutilized benefits can ensure they are fully informed and can make the most of their compensation package.",
      },
      {
        inf: "The company should consider conducting a benefits review to ensure the perks align with employee preferences and industry standards. Offering more personalized benefits, such as flexible work arrangements or additional health-related perks, can help boost this score.",
      },
      {
        inf: "Simplifying the process for accessing benefits, perhaps through a more user-friendly portal or improved HR support, can enhance the employee experience. Making sure employees know how to easily use their benefits will increase their overall satisfaction with the compensation package.",
      },
      {
        inf: "The company should review its performance metrics to ensure they accurately reflect employee contributions and organizational goals. Involving employees in setting and understanding these metrics can help them feel more engaged and aligned with company objectives.",
      },
      {
        inf: "To improve this perception, the company should invest in optimizing processes and implementing more robust systems that support strategy execution. Regular process audits and feedback from employees can help identify and address inefficiencies.",
      },
      {
        inf: "The company should focus on refining its recruitment strategies to ensure it attracts top talent. Additionally, investing in management training can help leaders better manage and develop their teams, leading to higher employee satisfaction and retention.",
      },
    ],
    "Cultural Health": [
      {
        inf: "To maintain this positive perception, the company should continue to reinforce its core values in all communications and behaviors. Consistently aligning business decisions and leadership actions with these values will further strengthen employees' connection to the company’s mission.",
      },
      {
        inf: "To close this gap, the company can promote more value-driven behaviors by recognizing and rewarding employees who embody the company’s values. Leadership should also lead by example, consistently demonstrating the values in their actions to reinforce the cultural expectations.",
      },
      {
        inf: "The company can enhance this alignment by ensuring that all policies, practices, and interactions are guided by the company’s values. Embedding these values into everyday processes and decision-making frameworks will help strengthen the perception of a value-driven workplace.",
      },
      {
        inf: "To improve this, the company should focus on creating more meaningful and memorable employee experiences. Initiatives such as team-building events, company-wide celebrations, and personalized recognition moments can help employees feel more connected and valued.",
      },
      {
        inf: "To make people practices more 'brag-worthy,' the company should consider implementing innovative and employee-centered programs, such as flexible working arrangements, wellness initiatives, or development opportunities. Highlighting success stories within the company can also help employees take pride in the company’s people practices.",
      },
      {
        inf: "To maintain and further strengthen this perception, the company should continue to uphold high ethical standards and openly communicate about ethical decision-making. Consistently demonstrating ethical behavior, especially at the leadership level, will help maintain this positive outlook.",
      },
      {
        inf: "To improve this, the company can review and update its ethical conduct policies, ensuring they are comprehensive and transparent. Providing regular training on ethical behavior and reinforcing the importance of ethics in everyday work will help employees feel more confident in the company’s commitment to ethical conduct.",
      },
      {
        inf: "The company should focus on strengthening diversity and inclusion initiatives to ensure that all employees feel fairly treated. Regularly communicating about the importance of equality, offering diversity training, and actively monitoring diversity metrics will help improve this perception.",
      },
      {
        inf: "To address this, the company should ensure that its performance evaluation processes are transparent and objective. Offering more opportunities for employee feedback and making the reward system more merit-based can help build trust in these processes.",
      },
      {
        inf: "The company can improve this score by creating more inviting and collaborative spaces where employees can socialize and relax. Offering communal areas, organizing informal social events, or creating flexible working spaces can help foster a more inclusive and comfortable work environment.",
      },
    ],
    "Social Health": [
      {
        inf: "To maintain this strong sense of inclusion, the company should continue promoting teamwork and collaboration. Recognizing employees for their contributions and ensuring that all voices are heard during decision-making processes will further strengthen this positive perception.",
      },
      {
        inf: "The company should foster more opportunities for collaboration, such as cross-departmental projects, brainstorming sessions, or team-building activities. Encouraging more frequent and meaningful collaboration will help employees feel more connected to their teams and the larger organizational mission.",
      },
      {
        inf: "The company can work on building a more open and transparent communication culture by encouraging candid feedback and regular discussions. Leadership can lead by example by being open and receptive to feedback, which will encourage employees to engage in more honest and open conversations.",
      },
      {
        inf: "To improve this, the company should focus on celebrating both team and individual successes more regularly. Introducing formal and informal recognition events, celebrating key milestones, and creating a culture of appreciation will help build a more positive and celebratory environment.",
      },
      {
        inf: "The company can improve this by promoting team-building activities and open communication, which will help employees build stronger relationships. Encouraging collaboration and recognizing team efforts can also help increase mutual trust within the workforce.",
      },
    ],
    "Mental Health": [
      {
        inf: "To improve employee happiness and security, the company should focus on creating a more supportive environment, offering clear career growth opportunities, and reinforcing job stability. Regular check-ins with employees to discuss their concerns and providing assurance can help boost job satisfaction.",
      },
      {
        inf: "To sustain this positive trend, the company should continue monitoring employee workloads and offer flexibility when needed. Regularly checking in on employees’ workload and ensuring that there is support during peak periods will help maintain this positive sentiment.",
      },
      {
        inf: "The company can improve this by fostering stronger social bonds through team-building activities, social events, and creating informal spaces where employees can connect. A supportive work environment where employees can rely on one another will improve overall well-being and satisfaction.",
      },
      {
        inf: "To change this perception, the company should encourage a culture where taking leave is normalized and supported by managers. Clear communication from leadership on the importance of work-life balance, and ensuring that taking leave does not result in negative consequences, will help employees feel more comfortable taking personal time off.",
      },
      {
        inf: "The company can improve this by promoting clear boundaries between work and personal time for remote workers. Encouraging employees to set fixed working hours, and respecting personal time during remote work, will help reduce stress and improve mental well-being.",
      },
      {
        inf: "To address this, the company should offer more mental health resources, such as stress management workshops, access to counseling services, and mindfulness training. Providing employees with practical tools to cope with stress will improve their overall mental health and workplace satisfaction.",
      },
      {
        inf: "The company can strengthen this perception by promoting a culture of support and open communication. Encouraging managers to provide regular feedback, recognizing employee efforts, and ensuring a healthy work-life balance will help improve the overall work environment.",
      },
      {
        inf: "To improve this, the company should create more platforms for employees to express their opinions, such as regular feedback sessions, anonymous surveys, or open forums. Encouraging leadership to actively listen and act on employee feedback will help employees feel more valued and involved in decision-making.",
      },
    ],
    "Digital and Tech Health": [
      {
        inf: "To maintain this enthusiasm, the company should continue investing in cutting-edge technologies and actively involve employees in the decision-making process for new tech implementations. Offering regular updates on technological improvements and providing training on new tools will sustain employee engagement and excitement.",
      },
      {
        inf: "The company should build on this strength by continuously providing access to training and support to ensure employees can stay up-to-date with the latest tools. Encouraging a culture of continuous learning and adaptability will help maintain the organization’s competitive edge in technology adoption.",
      },
      {
        inf: "To sustain this positive impact, the company should continue investing in advanced technology and ensure that all employees have access to the tools and resources they need to succeed. Regular feedback on the effectiveness of technology in improving work processes will help the company stay aligned with employee needs.",
      },
      {
        inf: "The company can improve this by enhancing its use of data-driven decision-making across the organization. Offering training on data literacy, sharing more insights from analytics with employees, and ensuring that business decisions are transparently tied to data can improve the perception of how data and analytics are used.",
      },
    ],
  });

  const [customerName, setCustomerName] = useState();

  const createGradient = (ctx, progress) => {
    const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
    gradient.addColorStop(progress, "#CCE0FF");
    gradient.addColorStop(0, "#2860B5");
    return gradient;
  };

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${HOST_NAME}/route/get-health-score`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          setLevers(res.data.levers);
          console.log(res.data.category);
          setCategory(res.data.category);
        })
        .catch((err) => console.log(err));
    };

    const fetchInference = async () => {
      await axios
        .get(`${HOST_NAME}/route/get-inferences`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          setInferences(res.data.data.levers);
          console.log("Inferences", res.data.data);
        })
        .catch((err) => console.log(err));
    };

    const fetchInsights = async () => {
      await axios
        .get(`${HOST_NAME}/route/get-insights`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          setInsights(res.data.data.levers);
        })
        .catch((err) => console.log(err));
    };

    const fetchCustomer = async () => {
      await axios
        .get(`${HOST_NAME}/route/get-customer`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          console.log(res.data.data);
          setCustomerName(res.data.data.name);
        })
        .catch((err) => console.log(err));
    };

    // fetchInference();
    // fetchInsights();
    fetchCustomer();
    fetchData();

    Object.keys(levers).forEach((key, index) => {
      const chartRef = chartRefs.current[index];
      if (chartRef) {
        const ctx = chartRef.getContext("2d");
        const score = levers[key];
        const data = [score, 100 - score];
        const total = data.reduce((acc, value) => acc + value, 0);
        const progress = data[0] / total;
        const gradient = createGradient(ctx, progress);

        new Chart(ctx, {
          type: "doughnut", // Example chart type
          data: {
            datasets: [
              {
                data,
                backgroundColor: [
                  gradient,
                  "rgba(200, 200, 200, 0.5)", // Background color for the remaining segment
                ],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
          },
        });
      }
    });

    // if (chart) {
    //   const ctx = chart.ctx;

    //   const dataset = chart.data.datasets[0];
    //   const total = dataset.data.reduce((acc, value) => acc + value, 0);
    //   const progress = dataset.data[0] / total;

    //   const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
    //   gradient.addColorStop(progress, "#CCE0FF");
    //   //   gradient.addColorStop(progress, "#2860B5");
    //   //   gradient.addColorStop(progress, "#2860B5");
    //   gradient.addColorStop(0, "#2860B5");
    //   //   gradient.addColorStop(1, "#2860B5");

    //   dataset.backgroundColor = [
    //     gradient,
    //     "rgba(200, 200, 200, 0.5)", // Background color for the remaining segment
    //   ];

    //   chart.update();
    // }
    // if (chart1) {
    //   const ctx = chart1.ctx;

    //   const dataset = chart1.data.datasets[0];
    //   const total = dataset.data.reduce((acc, value) => acc + value, 0);
    //   const progress = dataset.data[0] / total;

    //   const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
    //   gradient.addColorStop(progress, "#CCE0FF");
    //   //   gradient.addColorStop(progress, "#2860B5");
    //   //   gradient.addColorStop(progress, "#2860B5");
    //   gradient.addColorStop(0, "#2860B5");
    //   //   gradient.addColorStop(1, "#2860B5");

    //   dataset.backgroundColor = [
    //     gradient,
    //     "rgba(200, 200, 200, 0.5)", // Background color for the remaining segment
    //   ];

    //   chart1.update();
    // }
  }, []);

  const physiological = {
    datasets: [
      {
        data: [
          levers["Physiological Health"],
          100 - levers["Physiological Health"],
        ],
        backgroundColor: [
          levers["Physiological Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const mental = {
    datasets: [
      {
        data: [levers["Mental Health"], 100 - levers["Mental Health"]],
        backgroundColor: [
          levers["Mental Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const social = {
    datasets: [
      {
        data: [levers["Social Health"], 100 - levers["Social Health"]],
        backgroundColor: [
          levers["Social Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const financial = {
    datasets: [
      {
        data: [levers["Financial Health"], 100 - levers["Financial Health"]],
        backgroundColor: [
          levers["Financial Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const cultural = {
    datasets: [
      {
        data: [levers["Cultural Health"], 100 - levers["Cultural Health"]],
        backgroundColor: [
          levers["Cultural Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const spiritual = {
    datasets: [
      {
        data: [levers["Spiritual Health"], 100 - levers["Spiritual Health"]],
        backgroundColor: [
          levers["Spiritual Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const digital = {
    datasets: [
      {
        data: [
          levers["Digital and Tech Health"],
          100 - levers["Digital and Tech Health"],
        ],
        backgroundColor: [
          levers["Digital and Tech Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const business = {
    datasets: [
      {
        data: [levers["Business Health"], 100 - levers["Business Health"]],
        backgroundColor: [
          levers["Business Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const intellectual = {
    datasets: [
      {
        data: [
          levers["Intellectual Health"],
          100 - levers["Intellectual Health"],
        ],
        backgroundColor: [
          levers["Intellectual Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const career = {
    datasets: [
      {
        data: [levers["Career Health"], 100 - levers["Career Health"]],
        backgroundColor: [
          levers["Career Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };

  const sum = (arr) => {
    return arr.reduce((a, b) => a + b, 0);
  };

  const LeverDetail = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    const leverDetails = [
      {
        overallscore: levers["Business Health"],
        overallscoredata: [
          levers["Business Health"],
          100 - levers["Business Health"],
        ],
        backgroundcolor: [
          levers["Business Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Business Health",
        labeldata: ["Leadership", "Brand", "Benefits", "Competence"],
        labelscore: [
          category["Leadership"],
          category["Brand"],
          category["Benefits"],
          category["Competence"],
        ],
        desc: "Business Health understands the perceived organizational value through brand resonance, leadership and impact creation.",
      },
      {
        overallscore: levers["Physiological Health"],
        overallscoredata: [
          levers["Physiological Health"],
          100 - levers["Physiological Health"],
        ],
        backgroundcolor: [
          levers["Physiological Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Physiological Health",
        labeldata: ["Well being", "Movement", "Energy"],
        labelscore: [
          category["Wellbeing"],
          category["Movement"],
          category["Energy"],
        ],
        desc: "Physiological Health assesses if people take up an active role in enhancing their physical well-being for optimal performance and productivity",
      },
      {
        overallscore: levers["Financial Health"],
        overallscoredata: [
          levers["Financial Health"],
          100 - levers["Financial Health"],
        ],
        backgroundcolor: [
          levers["Financial Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Financial Health",
        labeldata: [""],
        labelscore: [0],
        desc: "Financial Health examines employees' financial awareness of the status quo and efforts towards economic stability.",
      },
      {
        overallscore: levers["Intellectual Health"],
        overallscoredata: [
          levers["Intellectual Health"],
          100 - levers["Intellectual Health"],
        ],
        backgroundcolor: [
          levers["Intellectual Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Intellectual Health",
        labeldata: ["Learning", "Innovation", "Fitment"],
        labelscore: [
          category["Learning"],
          category["Innovation"],
          category["Fitment"],
        ],
        desc: "Intellectual Health indicates the level of synergy in the area to become a dynamic learning and innovative environment.",
      },
      {
        overallscore: levers["Mental Health"],
        overallscoredata: [
          levers["Mental Health"],
          100 - levers["Mental Health"],
        ],
        backgroundcolor: [
          levers["Mental Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Mental Health",
        labeldata: ["Motivation", "Support", "Expression"],
        labelscore: [
          category["Motivation"],
          category["Support"],
          category["Expression"],
        ],
        desc: "Mental Health examines if the workplace fosters emotional safety, ease of expression, and satisfaction for robust mental health.",
      },
      {
        overallscore: levers["Cultural Health"],
        overallscoredata: [
          levers["Cultural Health"],
          100 - levers["Cultural Health"],
        ],
        backgroundcolor: [
          levers["Cultural Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Cultural Health",
        labeldata: ["Values", "Practices", "Ethics", "Places"],
        labelscore: [
          category["Values"],
          category["Practices"],
          category["Ethics"],
          category["Places"],
        ],
        desc: "Cultural Health explores how the practices and values followed are unique, bragworthy, and effective in crafting a great experience.",
      },
      {
        overallscore: levers["Career Health"],
        overallscoredata: [
          levers["Career Health"],
          100 - levers["Career Health"],
        ],
        backgroundcolor: [
          levers["Career Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Career Health",
        labeldata: ["Recognition", "Mentorship", "Growth"],
        labelscore: [
          category["Recognition"],
          category["Mentorship"],
          category["Growth"],
        ],
        desc: "Career Health evaluates professional nurturing within the organization and provides overall security, ensuring not just job stability but a promising career.",
      },
      {
        overallscore: levers["Social Health"],
        overallscoredata: [
          levers["Social Health"],
          100 - levers["Social Health"],
        ],
        backgroundcolor: [
          levers["Social Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Social Health",
        labeldata: ["Collaboration", "Community"],
        labelscore: [category["Collaboration"], category["Community"]],
        desc: "Social Health assesses the sense of supportiveness, belongingness, and collaboration within the organizational fabric.",
      },
      {
        overallscore: levers["Spiritual Health"],
        overallscoredata: [
          levers["Spiritual Health"],
          100 - levers["Spiritual Health"],
        ],
        backgroundcolor: [
          levers["Spiritual Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Spiritual Health",
        labeldata: ["Purpose", "Impact"],
        labelscore: [category["Purpose"], category["Impact"]],
        desc: "Spiritual Health assesses the alignment of individual purpose and their inclination to a larger societal mission.",
      },
      {
        overallscore: levers["Digital and Tech Health"],
        overallscoredata: [
          levers["Digital and Tech Health"],
          100 - levers["Digital and Tech Health"],
        ],
        backgroundcolor: [
          levers["Digital and Tech Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Digital and Tech Health",
        labeldata: ["Adeptness", "Availability and Access"],
        labelscore: [
          category["Adeptness"],
          category["Availability and Access"],
        ],
        desc: "Tech & Digital Health indicates how well the organization has adopted Data & Technology to be a faster, better and a smarter workplace.",
      },
    ];

    const data = {
      datasets: [
        {
          data: leverDetails[leverDetailsIndex].overallscoredata,
          backgroundColor: leverDetails[leverDetailsIndex].backgroundcolor,
        },
      ],
    };

    const bardata = {
      labels: leverDetails[leverDetailsIndex].labeldata,
      datasets: [
        {
          backgroundColor: leverDetails[leverDetailsIndex].labelscore.map(
            (score) => (score < 75 ? BELOW_80 : "#278AFF")
          ),
          data: leverDetails[leverDetailsIndex].labelscore,
        },
      ],
    };

    const options = {
      maintainAspectRatio: false,
      layout: {
        padding: 0,
      },
      // plugins: {
      //   legend: {
      //     display: false,
      //   },
      // },
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          align: "top",
          padding: {
            right: 2,
          },

          labels: {
            padding: { top: 10 },
            title: {
              font: {
                weight: "bold",
              },
            },
            value: {
              color: leverDetails[leverDetailsIndex].labelscore.map((score) => {
                return score < 75 ? "#278AFF" : BELOW_80;
              }),
            },
          },
          formatter: function (value) {
            return "\n" + value + "%";
          },
        },

        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || "";
              if (label) {
                label += ": ";
              }
              label += context.parsed.y + "%";
              return label;
            },
          },
        },
        legend: {
          display: false,
        },
      },

      scales: {
        x: {
          display: true,
          grid: {
            display: false,
          },
        },
        y: {
          display: false,
        },
      },
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "80vw",
        }}
      >
        <MdArrowBack
          onClick={() => setIsDashboard(true)}
          style={{
            cursor: "pointer",
            width: "30px",
            height: "30px",
          }}
          size={25}
        />
        {/* <div
            style={{ width: "13px", height: "3px", backgroundColor: "#000" }}
          />
          <div
            style={{
              width: "7px",
              height: "3px",
              backgroundColor: "#000",
              transform: "rotate(45deg)",
              position: "relative",
            }}
          />
          <div
            style={{
              width: "7px",
              height: "3px",
              backgroundColor: "#000",
              transform: "rotate(-45deg)",
              position: "relative",
              top: "-10px",
            }}
          /> */}
        <div
          style={{
            width: "60vw",
            height: "30%",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "20px",
            paddingLeft: "20px",
            paddingTop: "0px",
            paddingBottom: "20px",
            marginTop: "20px",
          }}
        >
          <p
            style={{
              fontWeight: "600",
              fontSize: "24px",
              color: "#30373E",
              marginLeft: "10px",
            }}
          >
            {leverDetails[leverDetailsIndex].levername}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "60vw",
              marginTop: "-20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "5%",
              }}
            >
              <div style={{ width: "150px", height: "150px" }}>
                <Doughnut
                  data={data}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "18px",
                    color: "#2961B6",
                    marginTop: "-85px",
                    marginLeft: "60px",
                  }}
                >
                  {leverDetails[leverDetailsIndex].overallscore}%
                </p>
              </div>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "rgba(48, 55, 62, 0.7)",
                  width: "20vw",
                }}
              >
                {leverDetails[leverDetailsIndex].desc}
              </p>
            </div>

            <div>
              {bardata.datasets[0].data.length !== 1 && (
                <Bar
                  style={{ height: "200px", width: "300px" }}
                  data={bardata}
                  plugins={[ChartDataLabels]}
                  options={options}
                />
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "60vw",
            justifyContent: "space-around",
            marginTop: "5%",
            marginBottom: "5%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "40%",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: "0px",
              paddingBottom: "20px",
            }}
          >
            <p
              style={{ fontWeight: "600", fontSize: "24px", color: "#30373E" }}
            >
              Inferences
            </p>
            <div
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "rgba(48, 55, 62, 0.7)",
              }}
            >
              <ul>
                {inferences[leverDetails[leverDetailsIndex].levername].map(
                  (inf) => {
                    return <li>{inf["inf"]}</li>;
                  }
                )}
              </ul>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "40%",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "20px",
              paddingRight: "20px",
              paddingLeft: "20px",
              paddingTop: "0px",
              paddingBottom: "20px",
            }}
          >
            <p
              style={{ fontWeight: "600", fontSize: "24px", color: "#30373E" }}
            >
              Insights
            </p>
            <div
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "rgba(48, 55, 62, 0.7)",
              }}
            >
              <ul>
                {insights[leverDetails[leverDetailsIndex].levername].map(
                  (ins) => {
                    return <li>{ins["inf"]}</li>;
                  }
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DashboardLever = () => {
    return (
      <div>
        <p
          style={{
            color: "#30373E",
            fontSize: "24px",
            fontWeight: "700",
            width: "90%",
          }}
        >
          Dashboard
        </p>
        <div className="welcome-note">
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor:
                "linear-gradient(to right,rgba(0, 66, 166, 0.2),rgba(0, 66, 166, 1))",
              padding: "20px",
            }}
          >
            <p className="welcome-title">Hello {customerName}!</p>
            <p className="welcome-content">
              <span>
                We're thrilled to have you here! This dashboard is designed to
                provide you with a comprehensive overview of your organization's
                health and well-being, based on the recent Thriving Workplace
                Survey.
              </span>
              <span>
                Gain a quick snapshot of the overall health and well-being of
                your organization, including key metrics and trends.
              </span>
              <span>
                Explore specific insights for different departments, helping you
                understand unique strengths and challenges across your
                organization.
              </span>
              <span>
                You are one step closer to making Become a thriving workplace
              </span>
            </p>
          </div>
          {/* <div style={{ width: "80%" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={require("../assets/welcome-card.png")}
            />
          </div> */}
        </div>
        <p
          style={{
            color: "#30373E",
            fontSize: "24px",
            fontWeight: "600",
            width: "90%",
          }}
        >
          Health Levers
        </p>
        <div className="lever-container">
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(1);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/physiological-health-icon.png")}
                />
              </div>
              <p className="lever-name">Physiological Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={physiological}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Physiological Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Wellbeing"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Well-Being</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Movement"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Movement</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Energy"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Energy</p>
                </div>
              </div>
            </div>
            <p>
              Physiological Health assesses if people take up an active role in
              enhancing their physical well-being for optimal performance and
              productivity
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(4);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/mental-health-icon.png")}
                />
              </div>
              <p className="lever-name">Mental Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={mental}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Mental Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Motivation"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Motivation</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Support"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Support</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Expression"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Expression</p>
                </div>
              </div>
            </div>
            <p>
              Mental Health examines if the workplace fosters emotional safety,
              ease of expression, and satisfaction for robust mental health
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(0);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/business-health-icon.png")}
                />
              </div>
              <p className="lever-name">Business Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={business}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Business Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "-40px",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Leadership"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Leadership</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Brand"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Brand</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Benefits"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px", width: "76px" }}>
                    Compensation & benefits
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Competence"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Competence</p>
                </div>
              </div>
            </div>
            <p>
              Business Health understands the perceived organizational value
              through brand resonance, leadership and impact creation.
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(2);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/financial-health-icon.png")}
                />
              </div>
              <p className="lever-name">Financial Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={financial}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Financial Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Status quo"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Status quo</p>
                </div>
              </div>
            </div>
            <p>
              Financial Health examines employees' financial awareness of the
              status quo and efforts towards economic stability.
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(5);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/cultural-health-icon.png")}
                />
              </div>
              <p className="lever-name">Cultural Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={cultural}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Cultural Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "-40px",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Values"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Values</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Practices"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Practices</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Ethics"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Ethics</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Places"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Places</p>
                </div>
              </div>
            </div>
            <p>
              Cultural Health explores how the practices and values followed are
              unique, bragworthy, and effective in crafting a great experience
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(8);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/spiritual-health-icon.png")}
                />
              </div>
              <p className="lever-name">Spiritual Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={spiritual}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Spiritual Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Purpose"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Purpose</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Impact"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Impact</p>
                </div>
              </div>
            </div>
            <p>
              Spiritual Health assesses the alignment of individual purpose and
              their inclination to a larger societal mission
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(3);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/intellectual-health-icon.png")}
                />
              </div>
              <p className="lever-name">Intellectual Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={intellectual}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Intellectual Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Learning"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Learning</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Innovation"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Innovation</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Fitment"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Fitment</p>
                </div>
              </div>
            </div>
            <p>
              Intellectual Health indicates the level of synergy in the area to
              become a dynamic learning and innovative environment.
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(7);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/social-health-icon.png")}
                />
              </div>
              <p className="lever-name">Social Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={social}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Social Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Collaboration"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Collaboration</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Community"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Community</p>
                </div>
              </div>
            </div>
            <p>
              Social Health assesses the sense of supportiveness, belongingness,
              and collaboration within the organizational fabric
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(6);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/career-health-icon.png")}
                />
              </div>
              <p className="lever-name">Career Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={career}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Career Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Recognition"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Recognition</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Mentorship"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Mentorship</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Learning"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Growth</p>
                </div>
              </div>
            </div>
            <p>
              Career Health evaluates professional nurturing within the
              organization and provides overall security, ensuring not just job
              stability but a promising career
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(9);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/digital-health-icon.png")}
                />
              </div>
              <p className="lever-name">Digital and Tech Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={digital}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Digital and Tech Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Adeptness"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Adeptness</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Availability and Access"] < 75
                          ? BELOW_80
                          : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Availability and Access</p>
                </div>
              </div>
            </div>
            <p>
              Tech & Digital Health indicates how well the organization has
              adopted Data & Technology to be a faster, better and a smarter
              workplace
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="dashboard-container">
      {isDashboard && <DashboardLever />}
      {!isDashboard && <LeverDetail />}
    </div>
  );
};

export default Dashboard;
