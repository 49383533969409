import React, { useRef, useEffect, useState } from "react";
import "./CSS/Dashboard.css";
import { BELOW_80, HOST_NAME } from "./const";
import { Doughnut, Bar } from "react-chartjs-2";
import { MdArrowBack } from "react-icons/md";
import { Chart, registerables } from "chart.js";
import axios from "axios";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Cookies from "js-cookie";
import { GrAttachment } from "react-icons/gr";
Chart.register(...registerables);

const Dashboard = () => {
  const chartRefs = useRef([]);

  const [levers, setLevers] = useState([]);
  const [category, setCategory] = useState([]);
  const [isDashboard, setIsDashboard] = useState(true);
  const [leverDetailsIndex, setLeverDetailsIndex] = useState(null);

  const [inferences, setInferences] = useState();
  const [insights, setInsights] = useState();

  const [organization, setOrganization] = useState(null);

  const [customerName, setCustomerName] = useState();

  const createGradient = (ctx, progress) => {
    const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
    gradient.addColorStop(progress, "#CCE0FF");
    gradient.addColorStop(0, "#2860B5");
    return gradient;
  };

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${HOST_NAME}/route/get-health-score`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          setLevers(res.data.levers);
          console.log("Response: ", res.data);
          setCategory(res.data.category);
        })
        .catch((err) => console.log(err));
    };

    const fetchInference = async () => {
      await axios
        .get(`${HOST_NAME}/route/get-inferences`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          setInferences(res.data.data.levers);
          console.log("Inferences", res.data.data);
        })
        .catch((err) => console.log(err));
    };

    fetchInference();

    const fetchInsights = async () => {
      await axios
        .get(`${HOST_NAME}/route/get-insights`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          setInsights(res.data.data.levers);
        })
        .catch((err) => console.log(err));
    };

    fetchInsights();

    const fetchCustomer = async () => {
      await axios
        .get(`${HOST_NAME}/route/get-customer`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          console.log(res.data.data);
          setCustomerName(res.data.data.name);
        })
        .catch((err) => console.log(err));
    };

    const fetchOrganization = async () => {
      await axios
        .get(`${HOST_NAME}/route/get-organization`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          setOrganization(res.data.data);
        })
        .catch((err) => console.log(err));
    };

    fetchOrganization();
    // fetchInference();
    // fetchInsights();
    fetchCustomer();
    fetchData();

    Object.keys(levers).forEach((key, index) => {
      const chartRef = chartRefs.current[index];
      if (chartRef) {
        const ctx = chartRef.getContext("2d");
        const score = levers[key];
        const data = [score, 100 - score];
        const total = data.reduce((acc, value) => acc + value, 0);
        const progress = data[0] / total;
        const gradient = createGradient(ctx, progress);

        new Chart(ctx, {
          type: "doughnut", // Example chart type
          data: {
            datasets: [
              {
                data,
                backgroundColor: [
                  gradient,
                  "rgba(200, 200, 200, 0.5)", // Background color for the remaining segment
                ],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
          },
        });
      }
    });

    // if (chart) {
    //   const ctx = chart.ctx;

    //   const dataset = chart.data.datasets[0];
    //   const total = dataset.data.reduce((acc, value) => acc + value, 0);
    //   const progress = dataset.data[0] / total;

    //   const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
    //   gradient.addColorStop(progress, "#CCE0FF");
    //   //   gradient.addColorStop(progress, "#2860B5");
    //   //   gradient.addColorStop(progress, "#2860B5");
    //   gradient.addColorStop(0, "#2860B5");
    //   //   gradient.addColorStop(1, "#2860B5");

    //   dataset.backgroundColor = [
    //     gradient,
    //     "rgba(200, 200, 200, 0.5)", // Background color for the remaining segment
    //   ];

    //   chart.update();
    // }
    // if (chart1) {
    //   const ctx = chart1.ctx;

    //   const dataset = chart1.data.datasets[0];
    //   const total = dataset.data.reduce((acc, value) => acc + value, 0);
    //   const progress = dataset.data[0] / total;

    //   const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
    //   gradient.addColorStop(progress, "#CCE0FF");
    //   //   gradient.addColorStop(progress, "#2860B5");
    //   //   gradient.addColorStop(progress, "#2860B5");
    //   gradient.addColorStop(0, "#2860B5");
    //   //   gradient.addColorStop(1, "#2860B5");

    //   dataset.backgroundColor = [
    //     gradient,
    //     "rgba(200, 200, 200, 0.5)", // Background color for the remaining segment
    //   ];

    //   chart1.update();
    // }
  }, []);

  const physiological = {
    datasets: [
      {
        data: [
          levers["Physiological Health"],
          100 - levers["Physiological Health"],
        ],
        backgroundColor: [
          levers["Physiological Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const mental = {
    datasets: [
      {
        data: [levers["Mental Health"], 100 - levers["Mental Health"]],
        backgroundColor: [
          levers["Mental Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const social = {
    datasets: [
      {
        data: [levers["Social Health"], 100 - levers["Social Health"]],
        backgroundColor: [
          levers["Social Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const financial = {
    datasets: [
      {
        data: [levers["Financial Health"], 100 - levers["Financial Health"]],
        backgroundColor: [
          levers["Financial Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const cultural = {
    datasets: [
      {
        data: [levers["Cultural Health"], 100 - levers["Cultural Health"]],
        backgroundColor: [
          levers["Cultural Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const spiritual = {
    datasets: [
      {
        data: [levers["Spiritual Health"], 100 - levers["Spiritual Health"]],
        backgroundColor: [
          levers["Spiritual Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const digital = {
    datasets: [
      {
        data: [
          levers["Digital and Tech Health"],
          100 - levers["Digital and Tech Health"],
        ],
        backgroundColor: [
          levers["Digital and Tech Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const business = {
    datasets: [
      {
        data: [levers["Business Health"], 100 - levers["Business Health"]],
        backgroundColor: [
          levers["Business Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const intellectual = {
    datasets: [
      {
        data: [
          levers["Intellectual Health"],
          100 - levers["Intellectual Health"],
        ],
        backgroundColor: [
          levers["Intellectual Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const career = {
    datasets: [
      {
        data: [levers["Career Health"], 100 - levers["Career Health"]],
        backgroundColor: [
          levers["Career Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };

  const sum = (arr) => {
    return arr.reduce((a, b) => a + b, 0);
  };

  const LeverDetail = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    const leverDetails = [
      {
        overallscore: levers["Business Health"],
        overallscoredata: [
          levers["Business Health"],
          100 - levers["Business Health"],
        ],
        backgroundcolor: [
          levers["Business Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Business Health",
        labeldata: ["Leadership", "Brand", "Compensation", "Competence"],
        labelscore: [
          category["Leadership"],
          category["Brand"],
          category["Benefits"],
          category["Competence"],
        ],
        desc: "Business Health understands the perceived organizational value through brand resonance, leadership and impact creation.",
      },
      {
        overallscore: levers["Physiological Health"],
        overallscoredata: [
          levers["Physiological Health"],
          100 - levers["Physiological Health"],
        ],
        backgroundcolor: [
          levers["Physiological Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Physiological Health",
        labeldata: ["Well being", "Movement", "Energy"],
        labelscore: [
          category["Wellbeing"],
          category["Movement"],
          category["Energy"],
        ],
        desc: "Physiological Health assesses if people take up an active role in enhancing their physical well-being for optimal performance and productivity",
      },
      {
        overallscore: levers["Financial Health"],
        overallscoredata: [
          levers["Financial Health"],
          100 - levers["Financial Health"],
        ],
        backgroundcolor: [
          levers["Financial Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Financial Health",
        labeldata: [""],
        labelscore: [0],
        desc: "Financial Health examines employees' financial awareness of the status quo and efforts towards economic stability.",
      },
      {
        overallscore: levers["Intellectual Health"],
        overallscoredata: [
          levers["Intellectual Health"],
          100 - levers["Intellectual Health"],
        ],
        backgroundcolor: [
          levers["Intellectual Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Intellectual Health",
        labeldata: ["Learning", "Innovation", "Fitment"],
        labelscore: [
          category["Learning"],
          category["Innovation"],
          category["Fitment"],
        ],
        desc: "Intellectual Health indicates the level of synergy in the area to become a dynamic learning and innovative environment.",
      },
      {
        overallscore: levers["Mental Health"],
        overallscoredata: [
          levers["Mental Health"],
          100 - levers["Mental Health"],
        ],
        backgroundcolor: [
          levers["Mental Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Mental Health",
        labeldata: ["Motivation", "Support", "Expression"],
        labelscore: [
          category["Motivation"],
          category["Support"],
          category["Expression"],
        ],
        desc: "Mental Health examines if the workplace fosters emotional safety, ease of expression, and satisfaction for robust mental health.",
      },
      {
        overallscore: levers["Cultural Health"],
        overallscoredata: [
          levers["Cultural Health"],
          100 - levers["Cultural Health"],
        ],
        backgroundcolor: [
          levers["Cultural Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Cultural Health",
        labeldata: ["Values", "Practices", "Ethics", "Places"],
        labelscore: [
          category["Values"],
          category["Practices"],
          category["Ethics"],
          category["Places"],
        ],
        desc: "Cultural Health explores how the practices and values followed are unique, bragworthy, and effective in crafting a great experience.",
      },
      {
        overallscore: levers["Career Health"],
        overallscoredata: [
          levers["Career Health"],
          100 - levers["Career Health"],
        ],
        backgroundcolor: [
          levers["Career Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Career Health",
        labeldata: ["Recognition", "Mentorship", "Growth"],
        labelscore: [
          category["Recognition"],
          category["Mentorship"],
          category["Growth"],
        ],
        desc: "Career Health evaluates professional nurturing within the organization and provides overall security, ensuring not just job stability but a promising career.",
      },
      {
        overallscore: levers["Social Health"],
        overallscoredata: [
          levers["Social Health"],
          100 - levers["Social Health"],
        ],
        backgroundcolor: [
          levers["Social Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Social Health",
        labeldata: ["Collaboration", "Community"],
        labelscore: [category["Collaboration"], category["Community"]],
        desc: "Social Health assesses the sense of supportiveness, belongingness, and collaboration within the organizational fabric.",
      },
      {
        overallscore: levers["Spiritual Health"],
        overallscoredata: [
          levers["Spiritual Health"],
          100 - levers["Spiritual Health"],
        ],
        backgroundcolor: [
          levers["Spiritual Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Spiritual Health",
        labeldata: ["Purpose", "Impact"],
        labelscore: [category["Purpose"], category["Impact"]],
        desc: "Spiritual Health assesses the alignment of individual purpose and their inclination to a larger societal mission.",
      },
      {
        overallscore: levers["Digital and Tech Health"],
        overallscoredata: [
          levers["Digital and Tech Health"],
          100 - levers["Digital and Tech Health"],
        ],
        backgroundcolor: [
          levers["Digital and Tech Health"] < 75 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Digital and Tech Health",
        labeldata: ["Adeptness", "Availability and Access"],
        labelscore: [
          category["Adeptness"],
          category["Availability and Access"],
        ],
        desc: "Tech & Digital Health indicates how well the organization has adopted Data & Technology to be a faster, better and a smarter workplace.",
      },
    ];

    const data = {
      datasets: [
        {
          data: leverDetails[leverDetailsIndex].overallscoredata,
          backgroundColor: leverDetails[leverDetailsIndex].backgroundcolor,
        },
      ],
    };

    const bardata = {
      labels: leverDetails[leverDetailsIndex].labeldata,
      datasets: [
        {
          backgroundColor: leverDetails[leverDetailsIndex].labelscore.map(
            (score) => (score < 75 ? BELOW_80 : "#278AFF")
          ),
          data: leverDetails[leverDetailsIndex].labelscore,
        },
      ],
    };

    const options = {
      maintainAspectRatio: false,
      layout: {
        padding: 0,
      },
      // plugins: {
      //   legend: {
      //     display: false,
      //   },
      // },
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          align: "top",
          padding: {
            right: 2,
          },

          labels: {
            padding: { top: 10 },
            title: {
              font: {
                weight: "bold",
              },
            },
            value: {
              color: leverDetails[leverDetailsIndex].labelscore.map((score) => {
                return score < 75 ? "#278AFF" : BELOW_80;
              }),
            },
          },
          formatter: function (value) {
            return "\n" + value + "%";
          },
        },

        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || "";
              if (label) {
                label += ": ";
              }
              label += context.parsed.y + "%";
              return label;
            },
          },
        },
        legend: {
          display: false,
        },
      },

      scales: {
        x: {
          display: true,
          grid: {
            display: false,
          },
        },
        y: {
          display: false,
        },
      },
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "80vw",
        }}
      >
        <MdArrowBack
          onClick={() => setIsDashboard(true)}
          style={{
            cursor: "pointer",
            width: "30px",
            height: "30px",
          }}
          size={25}
        />
        {/* <div
            style={{ width: "13px", height: "3px", backgroundColor: "#000" }}
          />
          <div
            style={{
              width: "7px",
              height: "3px",
              backgroundColor: "#000",
              transform: "rotate(45deg)",
              position: "relative",
            }}
          />
          <div
            style={{
              width: "7px",
              height: "3px",
              backgroundColor: "#000",
              transform: "rotate(-45deg)",
              position: "relative",
              top: "-10px",
            }}
          /> */}
        <div
          style={{
            width: "60vw",
            height: "30%",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "20px",
            paddingLeft: "20px",
            paddingTop: "0px",
            paddingBottom: "20px",
            marginTop: "20px",
          }}
        >
          <p
            style={{
              fontWeight: "600",
              fontSize: "24px",
              color: "#30373E",
              marginLeft: "10px",
            }}
          >
            {leverDetails[leverDetailsIndex].levername}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "60vw",
              marginTop: "-20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "5%",
              }}
            >
              <div style={{ width: "150px", height: "150px" }}>
                <Doughnut
                  data={data}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "18px",
                    color: "#2961B6",
                    marginTop: "-85px",
                    marginLeft: "60px",
                  }}
                >
                  {leverDetails[leverDetailsIndex].overallscore}%
                </p>
              </div>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "rgba(48, 55, 62, 0.7)",
                  width: "20vw",
                }}
              >
                {leverDetails[leverDetailsIndex].desc}
              </p>
            </div>

            <div>
              {bardata.datasets[0].data.length !== 1 && (
                <Bar
                  style={{ height: "200px", width: "300px" }}
                  data={bardata}
                  plugins={[ChartDataLabels]}
                  options={options}
                />
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "60vw",
            justifyContent: "space-around",
            marginTop: "5%",
            marginBottom: "5%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "40%",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: "0px",
              paddingBottom: "20px",
            }}
          >
            <p
              style={{ fontWeight: "600", fontSize: "24px", color: "#30373E" }}
            >
              Inferences
            </p>
            <div
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "rgba(48, 55, 62, 0.7)",
              }}
            >
              <ul>
                {inferences[leverDetails[leverDetailsIndex].levername].map(
                  (inf) => {
                    return <li>{inf["inf"]}</li>;
                  }
                )}
              </ul>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "40%",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "20px",
              paddingRight: "20px",
              paddingLeft: "20px",
              paddingTop: "0px",
              paddingBottom: "20px",
            }}
          >
            <p
              style={{ fontWeight: "600", fontSize: "24px", color: "#30373E" }}
            >
              Insights
            </p>
            <div
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "rgba(48, 55, 62, 0.7)",
              }}
            >
              <ul>
                {insights[leverDetails[leverDetailsIndex].levername].map(
                  (ins) => {
                    return <li>{ins["ins"]}</li>;
                  }
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DashboardLever = () => {
    return (
      <div>
        <p
          style={{
            color: "#30373E",
            fontSize: "24px",
            fontWeight: "700",
            width: "90%",
          }}
        >
          Dashboard
        </p>
        <div className="welcome-note">
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor:
                "linear-gradient(to right,rgba(0, 66, 166, 0.2),rgba(0, 66, 166, 1))",
              padding: "20px",
            }}
          >
            <p className="welcome-title">Hello {customerName}!</p>
            <p className="welcome-content">
              <span>
                We're thrilled to have you here! This dashboard is designed to
                provide you with a comprehensive overview of your organization's
                health and well-being, based on the recent Thriving Workplace
                Survey.
              </span>
              <span>
                Gain a quick snapshot of the overall health and well-being of
                your organization, including key metrics and trends.
              </span>
              <span>
                Explore specific insights for different departments, helping you
                understand unique strengths and challenges across your
                organization.
              </span>
              <span>
                You are one step closer to making
                {organization !== null ? organization.id === 1 ? " Become" : organization.id === 2 ? " Genesis" : organization.id === 4 ? " Unicom" : "" : ""} a thriving workplace
              </span>
            </p>
          </div>
          {/* <div style={{ width: "80%" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={require("../assets/welcome-card.png")}
            />
          </div> */}
        </div>
        <p
          style={{
            color: "#30373E",
            fontSize: "24px",
            fontWeight: "600",
            width: "90%",
          }}
        >
          Health Levers
        </p>
        <div className="lever-container">
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(1);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/physiological-health-icon.png")}
                />
              </div>
              <p className="lever-name">Physiological Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={physiological}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Physiological Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Wellbeing"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Well-Being</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Movement"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Movement</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Energy"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Energy</p>
                </div>
              </div>
            </div>
            <p>
              Physiological Health assesses if people take up an active role in
              enhancing their physical well-being for optimal performance and
              productivity
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(4);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/mental-health-icon.png")}
                />
              </div>
              <p className="lever-name">Mental Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={mental}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Mental Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Motivation"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Motivation</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Support"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Support</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Expression"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Expression</p>
                </div>
              </div>
            </div>
            <p>
              Mental Health examines if the workplace fosters emotional safety,
              ease of expression, and satisfaction for robust mental health
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(0);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/business-health-icon.png")}
                />
              </div>
              <p className="lever-name">Business Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={business}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Business Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "-40px",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Leadership"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Leadership</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Brand"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Brand</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Benefits"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px", width: "76px" }}>
                    Compensation
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Competence"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Competence</p>
                </div>
              </div>
            </div>
            <p>
              Business Health understands the perceived organizational value
              through brand resonance, leadership and impact creation.
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(2);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/financial-health-icon.png")}
                />
              </div>
              <p className="lever-name">Financial Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={financial}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Financial Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Status quo"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Status quo</p>
                </div>
              </div>
            </div>
            <p>
              Financial Health examines employees' financial awareness of the
              status quo and efforts towards economic stability.
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(5);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/cultural-health-icon.png")}
                />
              </div>
              <p className="lever-name">Cultural Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={cultural}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Cultural Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "-40px",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Values"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Values</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Practices"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Practices</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Ethics"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Ethics</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Places"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Places</p>
                </div>
              </div>
            </div>
            <p>
              Cultural Health explores how the practices and values followed are
              unique, bragworthy, and effective in crafting a great experience
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(8);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/spiritual-health-icon.png")}
                />
              </div>
              <p className="lever-name">Spiritual Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={spiritual}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Spiritual Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Purpose"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Purpose</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Impact"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Impact</p>
                </div>
              </div>
            </div>
            <p>
              Spiritual Health assesses the alignment of individual purpose and
              their inclination to a larger societal mission
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(3);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/intellectual-health-icon.png")}
                />
              </div>
              <p className="lever-name">Intellectual Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={intellectual}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Intellectual Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Learning"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Learning</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Innovation"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Innovation</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Fitment"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Fitment</p>
                </div>
              </div>
            </div>
            <p>
              Intellectual Health indicates the level of synergy in the area to
              become a dynamic learning and innovative environment.
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(7);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/social-health-icon.png")}
                />
              </div>
              <p className="lever-name">Social Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={social}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Social Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Collaboration"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Collaboration</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Community"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Community</p>
                </div>
              </div>
            </div>
            <p>
              Social Health assesses the sense of supportiveness, belongingness,
              and collaboration within the organizational fabric
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(6);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/career-health-icon.png")}
                />
              </div>
              <p className="lever-name">Career Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={career}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Career Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Recognition"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Recognition</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Mentorship"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Mentorship</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Learning"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Growth</p>
                </div>
              </div>
            </div>
            <p>
              Career Health evaluates professional nurturing within the
              organization and provides overall security, ensuring not just job
              stability but a promising career
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(9);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/digital-health-icon.png")}
                />
              </div>
              <p className="lever-name">Digital and Tech Health</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={digital}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Digital and Tech Health"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Adeptness"] < 75 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Adeptness</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Availability and Access"] < 75
                          ? BELOW_80
                          : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Availability and Access</p>
                </div>
              </div>
            </div>
            <p>
              Tech & Digital Health indicates how well the organization has
              adopted Data & Technology to be a faster, better and a smarter
              workplace
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="dashboard-container">
      {isDashboard && <DashboardLever />}
      {!isDashboard && <LeverDetail />}
    </div>
  );
};

export default Dashboard;
